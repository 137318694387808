import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.jsx";
import TagManager from "react-gtm-module";
import "./index.css";
import AnimationProvider from "./components/HeroAnimationStateProvider/AnimationProvider.jsx";

const tagManagerArgs = {
  gtmId: "GTM-MTLFQNSB",
};

TagManager.initialize(tagManagerArgs);

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <AnimationProvider>
      <App />
    </AnimationProvider>
  </StrictMode>
);
